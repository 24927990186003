export class TooltipManager {
    #tooltipCache = new Map(); // Cache tooltips by hex
    #currentTooltip = null;
    #baseUrl = `${window.location.protocol}//${window.location.host}`;
    #pendingRequests = new Map(); // Track in-progress requests
    #loading = false; // Global loading flag
    
    initialize() {
        console.log('TooltipManager initialized');
        this.#setupEventListeners();
        this.#createTooltipElement();
    }
    
    #createTooltipElement() {
        // Create tooltip element once at initialization
        const tooltip = document.createElement('div');
        tooltip.id = 'itemTooltipRender';
        tooltip.className = 'absolute bg-black bg-opacity-90 text-sm rounded-md p-3 text-center z-30 hidden';
        document.body.appendChild(tooltip);
    }

    #setupEventListeners() {
        // Use event delegation with debounce for mouseover events
        let hoverDebounceTimer = null;
        
        document.addEventListener('mouseover', (e) => {
            const itemElement = e.target.closest('.itemTooltipView');
            if (itemElement) {
                clearTimeout(hoverDebounceTimer);
                hoverDebounceTimer = setTimeout(() => {
                    this.#handleItemHover(itemElement, e);
                }, 10); // Short debounce to prevent duplicate events
            }
        });

        document.addEventListener('mouseout', (e) => {
            const itemElement = e.target.closest('.itemTooltipView');
            if (itemElement && !itemElement.contains(e.relatedTarget)) {
                clearTimeout(hoverDebounceTimer);
                this.hideTooltip();
            }
        });

        // Mouse move handling with throttling
        let lastMoveTime = 0;
        document.addEventListener('mousemove', (e) => {
            const now = Date.now();
            if (now - lastMoveTime > 16) { // ~60fps throttling
                this.#updateTooltipPosition(e);
                lastMoveTime = now;
            }
        });
    }

    async #handleItemHover(element, event) {
        if (!element) return;

        const hex = element.dataset.hex;
        if (!hex) return;
        
        // Store current element being hovered
        this.#currentTooltip = element;
        
        // Check cache first
        if (this.#tooltipCache.has(hex)) {
            this.#showTooltip(this.#tooltipCache.get(hex), event);
            return;
        }
        
        // Prevent duplicate fetches - if we're already loading this item, just wait
        if (this.#pendingRequests.has(hex)) {
            console.log('Already fetching item data for:', hex);
            this.#showTooltip('<div class="loading">Loading...</div>', event);
            
            // Wait for the pending request to complete
            try {
                const data = await this.#pendingRequests.get(hex);
                if (this.#currentTooltip === element) {
                    this.#showTooltip(this.#formatTooltipContent(data), event);
                }
            } catch (error) {
                console.error('Error with pending request:', error);
            }
            return;
        }
        
        // Set loading state and show loading placeholder
        this.#loading = true;
        this.#showTooltip('<div class="loading">Loading...</div>', event);
        
        try {
            console.log('Fetching item data for:', hex);
            
            // Create a promise for this request and store it BEFORE making the request
            const requestPromise = new Promise(async (resolve, reject) => {
                try {
                    const response = await fetch(`${this.#baseUrl}/api/item/${hex}`);
                    if (!response.ok) throw new Error('Network response was not ok');
                    const data = await response.json();
                    resolve(data);
                } catch (error) {
                    reject(error);
                }
            });
            
            // Store the promise immediately
            this.#pendingRequests.set(hex, requestPromise);
            
            // Wait for the request to complete
            const data = await requestPromise;
            
            // Cache the result
            this.#tooltipCache.set(hex, data);
            
            // Update tooltip if still hovering this item
            if (this.#currentTooltip === element) {
                this.#showTooltip(this.#formatTooltipContent(data), event);
            }
            
            // Reset states
            this.#loading = false;
            this.#pendingRequests.delete(hex);
            
        } catch (error) {
            console.error('Error loading tooltip:', error);
            this.#loading = false;
            this.#pendingRequests.delete(hex);
            this.#showTooltip('<div class="error">Could not load item data</div>', event);
        }
    }

    #formatTooltipContent(data) {
        // Format the JSON data returned by API into HTML
        if (typeof data === 'string') {
            return data; // Already formatted as HTML string
        }
        
        try {
            // If data is an object, format it to HTML
            let html = '';
            
            if (data.name) {
                html += `<div class="item-name ${data.rarity || ''}">${data.name}</div>`;
            }
            
            if (data.type) {
                html += `<div class="item-type">${data.type}</div>`;
            }
            
            if (data.stats && Array.isArray(data.stats)) {
                html += '<div class="item-stats">';
                data.stats.forEach(stat => {
                    html += `<div class="stat">${stat}</div>`;
                });
                html += '</div>';
            }
            
            if (data.description) {
                html += `<div class="item-description">${data.description}</div>`;
            }
            
            return html || JSON.stringify(data);
        } catch (error) {
            console.error('Error formatting tooltip data:', error);
            return '<div class="error">Error formatting item data</div>';
        }
    }

    #showTooltip(content, event) {
        const tooltip = document.getElementById('itemTooltipRender');
        tooltip.innerHTML = content;
        tooltip.classList.remove('hidden');
        this.#updateTooltipPosition(event);
    }

    #updateTooltipPosition(event) {
        if (!this.#currentTooltip) return;
        
        const tooltip = document.getElementById('itemTooltipRender');
        if (!tooltip || tooltip.classList.contains('hidden')) return;

        // Get the position of the hovered element
        const elementRect = this.#currentTooltip.getBoundingClientRect();
        const tooltipRect = tooltip.getBoundingClientRect();
        
        // Consider scroll position for absolute positioning
        const scrollX = window.scrollX || window.pageXOffset;
        const scrollY = window.scrollY || window.pageYOffset;
        
        // Position tooltip at the bottom of the element, centered horizontally
        let x = scrollX + elementRect.left + (elementRect.width / 2) - (tooltipRect.width / 2);
        let y = scrollY + elementRect.bottom + 5; // Small gap from the element
        
        // Boundary checking relative to viewport
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        
        if (x - scrollX < 0) x = scrollX; // Left boundary
        if (x + tooltipRect.width - scrollX > viewportWidth) {
            x = scrollX + viewportWidth - tooltipRect.width; // Right boundary
        }
        
        // If tooltip would go below viewport, show it above the element instead
        if (y - scrollY + tooltipRect.height > viewportHeight) {
            y = scrollY + elementRect.top - tooltipRect.height - 5;
        }
        
        // Apply the position
        tooltip.style.left = `${x}px`;
        tooltip.style.top = `${y}px`;
    }

    hideTooltip() {
        const tooltip = document.getElementById('itemTooltipRender');
        if (tooltip) {
            tooltip.classList.add('hidden');
        }
        this.#currentTooltip = null;
    }

    // Method to clear cache if needed
    clearCache() {
        this.#tooltipCache.clear();
        this.#pendingRequests.clear();
    }
}
