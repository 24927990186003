import { Messenger } from './classes/Messenger.js';
import { HTTPClient } from './classes/HTTPClient.js';
import { TooltipManager } from './classes/TooltipManager.js';
import { OnlinePlayersManager } from './classes/OnlinePlayersManager.js';
import { FORM_ENDPOINTS } from './config/endpoints.js';

class App {
    #http;
    #tooltipManager;
    #onlinePlayersManager;
    #loading = false;
    #isSending = false;
    #baseUrl = `${window.location.protocol}//${window.location.host}`;

    constructor() {
        this.#http = new HTTPClient();
        this.#tooltipManager = new TooltipManager();
        this.#onlinePlayersManager = new OnlinePlayersManager({
            http: this.#http,
            apiEndpoint: '/api/onlineUsers'
        });
    }

    initialize() {
        this.#setupEventListeners();
        this.#setupHTMXListeners();
        this.#tooltipManager.initialize();
        this.#initializeOnlinePlayersCounters();
        
    }

    #initializeOnlinePlayersCounters() {
        // Find all online players containers and initialize them
        const containers = document.querySelectorAll('.online-players-container');
        containers.forEach(container => {
            const playerCount = container.querySelector('#playerCount, .playerCount');
            const maxPlayers = container.querySelector('#maxPlayers, .maxPlayers');
            const progressBar = container.querySelector('#progressOnline, .progress-bar');
            
            if (playerCount && progressBar) {
                this.#onlinePlayersManager.initialize({
                    playerCount,
                    maxPlayers,
                    progressBar
                });
            }
        });
    }

    #setupEventListeners() {
        document.body.addEventListener('click', this.#handleClick.bind(this));
        document.body.addEventListener('submit', this.#handleSubmit.bind(this));
        document.body.addEventListener('change', this.#handleChange.bind(this));

        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === 1) {
                        this.#initializeNewContent(node);
                    }
                });
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    #setupHTMXListeners() {
        document.body.addEventListener('htmx:beforeSend', (event) => {
            if (event.detail.elt.getAttribute('hx-target') === '#main-content') {
                this.#showLoader();
            }
        });

        document.body.addEventListener('htmx:afterSettle', (event) => {
            if (event.detail.target?.id === 'main-content') {
                this.#hideLoader();
                this.#scrollToContent();
                this.#initializeNewContent(event.detail.target);
            }
        });

        document.body.addEventListener('htmx:responseError', () => {
            this.#hideLoader();
        });
    }

    #initializeNewContent(container) {
        const tooltips = container.querySelectorAll('.itemTooltipView');
        if (tooltips.length) {
            this.#tooltipManager.initialize();
        }
    }

    async #handleSubmit(event) {
        const form = event.target;
        if (!form.matches('form')) return;

        event.preventDefault();
        const formData = new FormData(form);
        const data = Object.fromEntries(formData);

        const endpoint = FORM_ENDPOINTS[form.id];
        if (!endpoint) return;

        const result = await this.#http.sendRequest(endpoint, 'POST', data);
        if (result?.success) {
            setTimeout(() => location.reload(), 5000);
        }
    }

    #handleClick(event) {
        const target = event.target;

        const clickHandlers = {
            '#logoutButton': () => this.#handleLogout(),
            '#newGameAccountView': () => this.#toggleNewGameAccount(),
            '#switchLanguage img': (element) => this.#handleLanguageSwitch(element)
        };

        for (const [selector, handler] of Object.entries(clickHandlers)) {
            if (target.matches(selector) || target.closest(selector)) {
                event.preventDefault();
                handler(target);
                break;
            }
        }
    }

    #handleChange(event) {
        const target = event.target;

        const changeHandlers = {
            'switchServer': (element) => this.#handleServerSwitch(element),
            'gameAccountSwitch': (element) => this.#handleAccountSwitch(element)
        };

        const handler = changeHandlers[target.id];
        if (handler) {
            event.preventDefault();
            handler(target);
        }
    }

    #showLoader() {
        if (this.#loading) return;
        this.#loading = true;

        const loader = document.createElement('div');
        loader.id = 'content-loader';
        loader.innerHTML = `
            <div class="fixed inset-0 bg-opacity-90 overflow-y-auto h-full w-full flex items-center justify-center z-50">
                <div class="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        `;
        document.body.appendChild(loader);
    }

    #hideLoader() {
        const loader = document.getElementById('content-loader');
        if (loader) {
            loader.remove();
            this.#loading = false;
        }
    }

    #scrollToContent() {
        setTimeout(() => {
            const mainContent = document.getElementById('main-content');
            if (mainContent) {
                mainContent.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
        }, 100);
    }

    async #handleLogout() {
        const result = await this.#http.sendRequest('/auth/logout', 'POST', {});
        if (result) {
            setTimeout(() => location.reload(), 5000);
        }
    }

    #toggleNewGameAccount() {
        const form = document.getElementById('newGameAccountViewForm');
        if (form) {
            form.classList.toggle('hidden');
        }
    }

    async #handleLanguageSwitch(element) {
        const lang = element.dataset.lang;
        await this.#http.sendRequest('/ajax/switchLanguage', 'POST', { lang });
        setTimeout(() => location.reload(), 5000);
    }

    async #handleServerSwitch(element) {
        await this.#http.sendRequest('/ajax/switchServer', 'POST', { server: element.value });
        setTimeout(() => location.reload(), 5000);
    }

    async #handleAccountSwitch(element) {
        await this.#http.sendRequest('/ajax/switchAccount', 'POST', { account: element.value });
        setTimeout(() => location.reload(), 5000);
    }

    async #handleConfirmPayment(element) {
        await this.#http.sendRequest('/payment/binance/simple/create', 'POST', { payment: element.value });
        setTimeout(() => location.reload(), 5000);
    }

    /**
     * Legacy Ajaxy implementation
     * @param {string} url - URL para a requisição
     * @param {string} type - Método HTTP (GET, POST, etc)
     * @param {Object} data - Dados a serem enviados
     * @param {boolean} [refresh=true] - Se deve atualizar a página após a resposta
     * @param {Function} [successCallback] - Callback opcional para sucesso
     * @param {Function} [errorCallback] - Callback opcional para erro
     * @returns {Promise} Promise com o resultado da requisição
     */
    async ajaxy(url, type, data, refresh = true, successCallback = null, errorCallback = null) {
        if (this.#isSending) {
            console.warn('Request already in progress');
            return false;
        }

        this.#isSending = true;
        window.isSending = true; // Legacy compatibility

        const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
        if (!csrfToken) {
            await Messenger.notice('Error', 'error', 'Invalid CSRF token', 5000, refresh);
            this.#isSending = false;
            window.isSending = false;
            return false;
        }

        try {
            const fullUrl = url.startsWith('http') ? url : `${this.#baseUrl}${url}`;
            const requestConfig = {
                method: type.toUpperCase(),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest'
                },
                credentials: 'same-origin'
            };

            if (type.toUpperCase() !== 'GET' && data) {
                requestConfig.body = JSON.stringify(data);
            }

            const response = await fetch(fullUrl, requestConfig);

            let result;
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                result = await response.json();
            } else {
                throw new Error('Invalid response format');
            }

            if (result.error || result.success) {
                const messageType = result.error ? 'error' : 'success';
                const message = result[messageType];

                await Messenger.notice(
                    messageType.charAt(0).toUpperCase() + messageType.slice(1),
                    messageType,
                    message,
                    5000,
                    refresh
                );

                if (result.success && typeof successCallback === 'function') {
                    successCallback(result);
                }
            }

            return result;

        } catch (error) {
            console.error('Ajaxy error:', error);

            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }

            await Messenger.notice(
                'Error',
                'error',
                'An unexpected error occurred. Please try again.',
                5000,
                refresh
            );

            return false;

        } finally {
            this.#isSending = false;
            window.isSending = false;
        }
    }

    /**
     * Legacy Ajaxy implementation
     * @param {string} url - URL para a requisição
     * @param {string} type - Método HTTP (GET, POST, etc)
     * @param {Object} data - Dados a serem enviados
     * @param {boolean} [refresh=true] - Se deve atualizar a página após a resposta
     * @param {Function} [successCallback] - Callback opcional para sucesso
     * @param {Function} [errorCallback] - Callback opcional para erro
     * @returns {Promise} Promise com o resultado da requisição
     */
    async ajax(url, type, data, refresh = true, successCallback = null, errorCallback = null) {
        if (this.#isSending) {
            console.warn('Request already in progress');
            return false;
        }

        this.#isSending = true;
        window.isSending = true; // Legacy compatibility

        const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
        if (!csrfToken) {
            await Messenger.notice('Error', 'error', 'Invalid CSRF token', 5000, refresh);
            this.#isSending = false;
            window.isSending = false;
            return false;
        }

        try {
            const fullUrl = url.startsWith('http') ? url : `${this.#baseUrl}${url}`;
            const requestConfig = {
                method: type.toUpperCase(),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest'
                },
                credentials: 'same-origin'
            };

            if (type.toUpperCase() !== 'GET' && data) {
                requestConfig.body = JSON.stringify(data);
            }

            const response = await fetch(fullUrl, requestConfig);

            let result;
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                result = await response.json();
            } else {
                throw new Error('Invalid response format');
            }

            if (result.error || result.success) {
                const messageType = result.error ? 'error' : 'success';
                const message = result[messageType];

                if (result.success && typeof successCallback === 'function') {
                    successCallback(result);
                }

            }

            return result;

        } catch (error) {
            console.error('Ajaxy error:', error);

            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }

            await Messenger.notice(
                'Error',
                'error',
                'An unexpected error occurred. Please try again.',
                5000,
                refresh
            );

            return false;

        } finally {
            this.#isSending = false;
            window.isSending = false;
        }
    }

    /**
     * Legacy FileAjaxy implementation for file uploads
     * @param {string} url - URL para upload
     * @param {string} type - Método HTTP (normalmente POST)
     * @param {FormData} formData - FormData contendo os arquivos
     * @param {boolean} [refresh=true] - Se deve atualizar a página após a resposta
     * @param {Function} [successCallback] - Callback opcional para sucesso
     * @param {Function} [errorCallback] - Callback opcional para erro
     * @returns {Promise} Promise com o resultado do upload
     */
    async fileAjaxy(url, type, formData, refresh = true, successCallback = null, errorCallback = null) {
        if (this.#isSending) {
            console.warn('Upload already in progress');
            return false;
        }

        this.#isSending = true;
        window.isSending = true; // Legacy compatibility

        const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;
        if (!csrfToken) {
            await Messenger.notice('Error', 'error', 'Invalid CSRF token', 5000, refresh);
            this.#isSending = false;
            window.isSending = false;
            return false;
        }

        try {
            const fullUrl = url.startsWith('http') ? url : `${this.#baseUrl}${url}`;
            const response = await fetch(fullUrl, {
                method: type.toUpperCase(),
                headers: {
                    'X-CSRF-TOKEN': csrfToken,
                    'X-Requested-With': 'XMLHttpRequest'
                },
                credentials: 'same-origin',
                body: formData
            });

            const result = await response.json();

            if (result.error || result.success) {
                const messageType = result.error ? 'error' : 'success';
                const message = result[messageType];

                await Messenger.notice(
                    messageType.charAt(0).toUpperCase() + messageType.slice(1),
                    messageType,
                    message,
                    5000,
                    refresh
                );

                if (result.success && typeof successCallback === 'function') {
                    successCallback(result);
                }
            }

            return result;

        } catch (error) {

            console.error('FileAjaxy error:', error);

            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }

            await Messenger.notice(
                'Error',
                'error',
                'File upload failed. Please try again.',
                5000,
                refresh
            );

            return false;

        } finally {
            this.#isSending = false;
            window.isSending = false;
        }
    }

    // Expose the online players manager for use in templates
    getOnlinePlayersManager() {
        return this.#onlinePlayersManager;
    }
}


// Inicialização quando o DOM estiver pronto
document.addEventListener('DOMContentLoaded', () => {
    const app = new App();
    window.app = app;
    app.initialize();
});