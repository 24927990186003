/**
 * Class responsible for managing and displaying online players count
 */
export class OnlinePlayersManager {
    #http;
    #playerCountElement;
    #maxPlayersElement;
    #progressBarElement;
    #updateInterval = 30000; // 30 seconds
    #intervalId = null;
    #apiEndpoint;

    /**
     * Create a new OnlinePlayersManager
     * @param {Object} options - Configuration options
     * @param {string} options.apiEndpoint - API endpoint to fetch online players data
     * @param {string} options.playerCountSelector - Selector for the player count element
     * @param {string} options.maxPlayersSelector - Selector for the max players element
     * @param {string} options.progressBarSelector - Selector for the progress bar element
     * @param {number} options.updateInterval - Update interval in milliseconds (default: 30000)
     * @param {Object} options.http - HTTP client instance
     */
    constructor(options = {}) {
        this.#apiEndpoint = options.apiEndpoint || '/api/onlineUsers';
        this.#http = options.http;
        this.#updateInterval = options.updateInterval || 30000;
    }

    /**
     * Initialize the manager
     * @param {Object} selectors - Elements or selectors
     * @param {string|Element} selectors.playerCount - Player count element or selector
     * @param {string|Element} selectors.maxPlayers - Max players element or selector
     * @param {string|Element} selectors.progressBar - Progress bar element or selector
     */
    initialize(selectors = {}) {
        // Get elements
        this.#playerCountElement = typeof selectors.playerCount === 'string' 
            ? document.querySelector(selectors.playerCount) 
            : selectors.playerCount;
            
        this.#maxPlayersElement = typeof selectors.maxPlayers === 'string'
            ? document.querySelector(selectors.maxPlayers)
            : selectors.maxPlayers;
            
        this.#progressBarElement = typeof selectors.progressBar === 'string'
            ? document.querySelector(selectors.progressBar)
            : selectors.progressBar;

        // Validate elements
        if (!this.#playerCountElement || !this.#progressBarElement) {
            console.error('OnlinePlayersManager: Required elements not found');
            return;
        }

        // Start updating
        this.startUpdating();
    }

    /**
     * Start updating the online players count
     */
    startUpdating() {
        // Clear any existing interval
        this.stopUpdating();
        
        // Initial update
        this.updateCounter();
        
        // Set interval for updates
        this.#intervalId = setInterval(() => this.updateCounter(), this.#updateInterval);
    }

    /**
     * Stop updating the online players count
     */
    stopUpdating() {
        if (this.#intervalId) {
            clearInterval(this.#intervalId);
            this.#intervalId = null;
        }
    }

    /**
     * Fetch online users data from the API
     * @returns {Promise<Object|null>} Online users data or null if error
     */
    async fetchOnlineUsers() {
        try {
            if (this.#http) {
                const result = await app.ajax(this.#apiEndpoint, 'GET', {});
                if (result.success) {
                    return result.success;
                }
            }
            return null;
        } catch (error) {
            console.error('Error fetching online users:', error);
            return null;
        }
    }

    /**
     * Update the counter with data from the API
     */
    async updateCounter() {
        const data = await this.fetchOnlineUsers();
        
        if (!data) {
            return;
        }
        
        // Update player count
        if (this.#playerCountElement) {
            this.#playerCountElement.textContent = data.total.toLocaleString();
        }
        
        // Update max players if element exists
        if (this.#maxPlayersElement) {
            this.#maxPlayersElement.textContent = data.max.toLocaleString();
        }
        
        // Update progress bar
        if (this.#progressBarElement) {
            const progress = (data.total / data.max) * 100;
            this.#progressBarElement.style.width = `${progress}%`;
            this.#progressBarElement.style.background = 'linear-gradient(90deg, #5c2575, #8033a1)';
            
            // Change color if server is full
            if (data.total >= data.max) {
                this.#playerCountElement.style.color = '#ff33ff';
                this.#progressBarElement.style.background = '#ff33ff';
            } else {
                this.#playerCountElement.style.color = 'white';
                this.#progressBarElement.style.background = 'linear-gradient(90deg, #5c2575, #8033a1)';
            }
        }

        // Dispatch event so other components can react
        document.dispatchEvent(new CustomEvent('onlinePlayersUpdated', { 
            detail: data 
        }));
    }
} 